<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label-for="admissionRoundId"
                  >
                    <v-select
                      v-model="filter.admissionRoundId"
                      :options="admissionRounds"
                      :reduce="option => option.value"
                      placeholder="Đợt xét tuyển"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label-for="identification"
                    title="CCCD Thí sinh ứng tuyển"
                  >
                    <b-form-input
                      id="code"
                      v-model="filter.identification"
                      name="name"
                      type="number"
                      placeholder="CCCD Thí sinh ứng tuyển"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                    @click="onSearchMainData"
                  >
                    <feather-icon icon="FilterIcon" /> Xem danh sách
                  </b-button>
                  <b-button
                    v-if="studentSuspendUpdatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="ml-1"
                    @click="onApproveSuspend(1)"
                  >
                    <feather-icon icon="CheckIcon" /> Xác nhận
                  </b-button>
                  <b-button
                    v-if="studentSuspendUpdatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="ml-1"
                    @click="onApproveSuspend(-1)"
                  >
                    <feather-icon icon="XOctagonIcon" /> Không xác nhận
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="downloadAccept"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất danh sách thí sinh trúng tuyển
                    </span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                    @click="downloadUnaccept"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất danh sách thí sinh trượt tuyển
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-0">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                </div>
                <vue-good-table
                  mode="remote"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'SV được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-selected-rows-change="selectionChanged"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-row-click="onRowClick"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="(props.row.status === 1) ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'isLeader'"
                      :class="props.row.isLeader !== 1 ? 'text-danger':'text-success font-weight-bolder'"
                    >
                      <feather-icon size="24" :icon="props.row.isLeader === 1 ? 'CheckIcon' : 'XIcon'" />
                    </span>
                    <span
                      v-else-if="props.column.field === 'isCentralLevel'"
                      :class="props.row.isCentralLevel !== 1 ? 'text-danger':'text-success font-weight-bolder'"
                    >
                      <feather-icon size="24" :icon="props.row.isCentralLevel === 1 ? 'CheckIcon' : 'XIcon'" />
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          variant="info"
                          class="btn-icon"
                          size="sm"
                        >
                          <feather-icon icon="ListIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <EditPoliticAdmissionApplicantStudent
        ref="saveModalRef"
        :item="politicAdmissionInfo"
        :status="accepted"
        @succeed="onSucceed"
      />
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { MIX_EXAMS } from "@/const/status";
import EditPoliticAdmissionApplicantStudent from "@/views/admission/politic-admission-confirm-application-student/EditPoliticAdmissionApplicantStudent.vue";

export default {
  name: 'ConfirmCertificate',
  directives: {
    Ripple,
  },
  components: {
    EditPoliticAdmissionApplicantStudent,
    BLink,
    BFormInput,
    BButton,
    BButtonGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        admissionRoundId: null,
        identification: null,
        code: null,
        sort: null,
        status: null,
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      currentName: '',
      currentCode: '',
      selectedMainData: [],
      politicAdmissionInfo: undefined,
      accepted: 0,
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'politicAdmissionConfirm/dataLists',
      totalRows: 'politicAdmissionConfirm/totalRows',
      statuses: 'politicAdmissionConfirm/statuses',
      resourceName: 'politicAdmissionConfirm/resourceName',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataExport: 'politicAdmissionConfirm/dataExport',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'CCCD',
          field: 'identification',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Họ tên',
          field: 'applicantStudentName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Ngày sinh',
          field: 'dob',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'SĐT',
          field: 'phone',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Trình độ GDPT',
          field: 'generalEducationLevel',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },{
          label: 'Trình độ CM',
          field: 'professionalQualification',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },{
          label: 'Chức vụ hiện tại',
          field: 'currentPosition',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Chức vụ quy hoạch',
          field: 'planningPosition',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Ngạch bậc, vị trí việc làm',
          field: 'workingRank',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Cơ quan đơn vị công tác',
          field: 'workingUnit',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Cơ quan chủ quản cơ quan, đơn vị công tác',
          field: 'workingOrganization',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Đối tượng',
          field: 'applicantType',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Cấp cơ quan',
          field: 'agencyLevel',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Là lãnh đạo',
          field: 'isLeader',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Là cơ quan thuộc ngành dọc trung ương',
          field: 'isCentralLevel',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Xử lý',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    studentSuspendUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.POLITIC_ADMISSION_CONFIRM)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization()    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'politicAdmissionConfirm/getData',
      updateData: 'politicAdmissionConfirm/updateData',
      getDataExport: 'politicAdmissionConfirm/downloadExport',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    onRowClick(param) {
      const { row } = param
      this.politicAdmissionInfo = row
      this.accepted = this.politicAdmissionInfo.status
      this.$bvModal.show('editPoliticAdmissionApplicantStudentModal')
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({
        applicantStudentId: e.applicantStudentId
      })) : []
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
    },
    async getMainDataFromStore() {
      this.isLoading = true
      if (this.filter.admissionRoundId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đợt xét tuyển không được để trống!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
        return
      } else {
        try {
          if (this.filter.identification === '') {
            this.filter.identification = null;
          }
          await this.getMainData(this.filter);
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          });
        } finally {
          this.isLoading = false
        }
      }
      this.isLoading = false
    },
    async downloadAccept() {
      if (this.filter.admissionRoundId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đợt xét tuyển không được để trống!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      } else {
        await this.getDataExport({
          admissionRoundId: this.filter.admissionRoundId,
          status: 1,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = `ds_thi_sinh_trung_tuyen.xlsx`
        a.click()
        window.URL.revokeObjectURL(url)
      }
    },
    async downloadUnaccept() {
      if (this.filter.admissionRoundId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đợt xét tuyển không được để trống!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      } else {
        await this.getDataExport({
          admissionRoundId: this.filter.admissionRoundId,
          status: -1,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = `ds_thi_sinh_truot_tuyen.xlsx`
        a.click()
        window.URL.revokeObjectURL(url)
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      const status = this.statuses.find(status => status.value === id);
      return status ? status.label : '';
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
    async onApproveSuspend(status) {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.isLoading = true
      try {
        const request = {
          applicantStudentIds: this.selectedMainData,
          status,
        }
        const response = await this.updateData(request)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getMainDataFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      return null
    },
  },
}
</script>
<style type="text/css">
</style>
